import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import firstCardAnimation from "./lottie/1st.json";
import harmonicWave from "./lottie/harmonicwaves.json";
import barGraph from "./lottie/bargraph.json";
import arc from './lottie/arc.json'
import statss from './lottie/montecarlo.json'

import "./calculatorselector.scss";

function CalculatorSelector() {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: firstCardAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const harmonicWaves = {
    loop: true,
    autoplay: true,
    animationData: harmonicWave,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const stats = {
    loop: true,
    autoplay: true,
    animationData: statss,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const BarGraph = {
    loop: true,
    autoplay: true,
    animationData: barGraph,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const Arc = {
    loop: true,
    autoplay: true,
    animationData: arc,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleNavigate = (path) => {
    console.log(path);
    navigate(`/${path}`);
  };
  return (
    <div className="container1">
      <div className="main-container1">
        <div className="main-container-heading">
          AC CALCULATORS
        </div>
        <div className="calculators">
          <div className="first-container">
            <Card
              sx={{ maxWidth: 345 }}
              className="first-card"
              onClick={() => handleNavigate("cable-size-calculator")}
            >
              <CardContent className="card-container">
                <div className="card-title">
                  <span>
                    <Lottie options={defaultOptions} height={40} width={40} />
                  </span>
                  <h2>AS3008.1.1 Cable Size Calculator</h2>
                </div>
                <div className="card-details">
                  Size cables in compliant with AS3008.1.1
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="second-container">
            <Card
              sx={{ maxWidth: 345 }}
              className="first-card"
              onClick={() => handleNavigate("maxdemandcalculator")}
            >
              <CardContent className="card-container">
                <div className="card-title">
                  <span>
                    <Lottie options={BarGraph} height={40} width={40} />
                  </span>
                  <h2>AS3000-2018 Max Demand Calculator</h2>
                </div>
                <div className="card-details">
                  Compute Maximum demand based on diversity factors from AS3000
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="third-container">
            <Card
              sx={{ maxWidth: 345 }}
              className="first-card"
              onClick={() => handleNavigate("ArcFlashCalculator")}
            >
              <CardContent className="card-container">
                <div className="card-title">
                  <span>
                    <Lottie options={Arc} height={40} width={40} />
                  </span>
                  <h2>Ac Arc Flash Calculator</h2>
                </div>
                <div className="card-details">
                Arc flash hazard analysis compliant with IEEE 1584 - 2018
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="third-container">
          <Card
            sx={{ maxWidth: 345 }}
            className="first-card"
            onClick={() => handleNavigate("harmonic")}
          >
            <CardContent className="card-container">
              <div className="card-title">
                <span>
                  <Lottie options={harmonicWaves} height={40} width={40} />
                </span>
                <h2>Harmonic Calculator</h2>
              </div>
              <div className="card-details">
                Accurate active and neutral current calculation with harmonics
              </div>
            </CardContent>
          </Card>
        </div>
        </div>
      </div>
      <div className="other-container">
      <div className="other-container-heading">
          DC CALCULATORS
        </div>

        <div className="calculators">
          <div className="first-container">
            <Card
              sx={{ maxWidth: 345 }}
              className="first-card"
              onClick={() => handleNavigate("ArcFlashCalculatorDC")}
            >
              <CardContent className="card-container">
                <div className="card-title">
                  <span>
                    <Lottie options={Arc} height={40} width={40} />
                  </span>
                  <h2>DC Arc Flash Calculator</h2>
                </div>
                <div className="card-details">
                  DC Arc flash hazard analysis using Maximum Power, Stokes and Paukerts method
                </div>
              </CardContent>
            </Card>
          </div>


        </div>
 

      </div>

      {/* <div className="main-container1">
        <div className="main-container-heading">
          OTHER CALCULATORS
        </div>
        <div className="calculators">
          <div className="third-container">
          <Card
            sx={{ maxWidth: 345 }}
            className="first-card"
            onClick={() => handleNavigate("SynthticLoadProfileCalculator")}
          >
            <CardContent className="card-container">
              <div className="card-title">
                <span>
                  <Lottie options={stats} height={40} width={40} />
                </span>
                <h2>Monte Carlo Simulation</h2>
              </div>
              <div className="card-details">
  Simulates load profiles with randomness for accurate predictions.
</div>
            </CardContent>
          </Card>
        </div>
        </div>
      </div> */}
      <div className="main-container1">
        

        <div className="calculators">


        </div>
      </div>
    </div>
  );
}

export default CalculatorSelector;
